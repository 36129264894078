import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },

  "calendar": {
    "today": {
      "background": "#c060a1",
      "text": "#ffdcb4",
      "border": "#ffdcb4"
    },
    "day": {
      "background": "#6a097d",
      "text": "#ffdcb4",
      "border": "#ffdcb4"
    },
    "past": {
      "background": "#33334b",
      "text": "#ffdcb4",
      "border": "#ffdcb4"
    },
    "empty": {
      "border": "#ffdcb4"
    },
    "weekdays": {
      "background": "#6a097d",
      "text": "#ffdcb4",
      "border": "#ffdcb4"
    },
    "event": {
      "background": "white",
      "text": "black"
    },
    "past-event": {
      "background": "grey",
      "text": "#ffdcb4"
    },
    "modal": {
      "text": "black",
      "background": "white"
    }
  }



});

export default function Hook() {
  const classes = useStyles();
  return <Button className={classes.root}>Hook</Button>;
}