import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import config from '../../data/SiteConfig'

export default function JTE() {
  return (
  <StaticQuery
  query = {graphql`
   query {
   image1: file(relativePath: { eq: "gallery/JTE1.jpg" }) {
     childImageSharp {
       fixed {
         ...GatsbyImageSharpFixed
         }
         }
         }
         }
        `}
  render={data => (
    <span>
     <Img fixed={data.image1.childImageSharp.fixed} />
    </span>
  )}
  />
  )
  }
  
