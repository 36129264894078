import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import Home from '../components/Home'
import config from '../../data/SiteConfig'
import Carousel from '../components/Carousel'
const IndexPage = () => (
  <Layout>
    <main>
      <Helmet title={`Home | ${config.siteTitle}`}>
	<link 
	rel="stylesheet"
	type="text/css"
	charset="UTF-8"
	href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
	/>
	<link
	rel="stylesheet"
	type="text/css"
	charset="UTF-8"
	href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
	/>
	</Helmet>
	
	<Home />
    </main>
  </Layout>
)

export default IndexPage
