import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import styles from './Footer.module.scss'
import config from '../../data/SiteConfig'


const sliderSettings = {
  autoplay: true,
  adaptiveHeight: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesPerRow: 2,
  Rows: 2,	
  slidesToScroll: 1,
}



export default function Carousel() {
  return (
  <StaticQuery
  query = {graphql`
  query myquery{
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(JPG)/" }
        relativeDirectory: { eq: "gallery/homeshow" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid (maxWidth: 450 maxHeight: 325) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
              } 
              }
        }
      }
    }
  }
        `}
  render={data => (
    <span>
    <Slider {...sliderSettings}>
    {data.allFile.edges.map(image => (   
          <Img fluid={image.node.childImageSharp.fluid} />
      ))}
     </Slider>
     </span>
  )}
  />
  )
  }
  

