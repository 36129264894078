
import React from "react"
import RGF from 'react-google-forms'
import config from '../../data/SiteConfig'
import classes from '../../src/gatsby-theme-material-ui-top-layout/style'
import { Button } from 'gatsby-theme-material-ui';
import ContactImage from './ContactImage';


const settings = {
    formURL: "https://docs.google.com/forms/d/e/1FAIpQLScCbTdOkF8KUijvxQzgdAmzXkJrZT_qMThvqLrVXxIvklAepg/viewform?embedded=true",
    dev: false,
    inputs: [
  {type:'text', options:{text:'one'}},
  {type:'text', options:{text:'two'}},
  {type:'text', options:{text:'three'}}
    ],
    redirectURL: "https://wwww.google.com",
     }

     export default function Contact() {
      return (
<div>
<form method="POST" action="https://docs.google.com/forms/d/e/1FAIpQLScCbTdOkF8KUijvxQzgdAmzXkJrZT_qMThvqLrVXxIvklAepg/formResponse?embedded=true">
	      <h1>Contact Troop 622</h1>
        <span><ContactImage /></span>
        <p>Interested in joining the troop, coming to one of our meetings or events, or learning more about scouting? We are waiting to hear from you! Please submit this form and we will get back to you quickly. You can also reach our scoutmaster by phone at (425) 381-6418</p>
	      
               <p> <div>Your Name:</div> <input type="text" placeholder="Your Name" name="entry.54368848" required="" /><br/></p>
                <p><div>Your Email Address: </div><input type="text" name="entry.1385688473" placeholder="Email Address" required="" /><br/></p>
               <p><div>Your question/comments:</div> <input type="text" name="mytext" placeholder="Subject" /><br/></p>
               <textarea name="entry.1265763312" cols="40" rows="5"></textarea>    
              <button name="submit" type="submit" value="fav_HTML">Submit</button>
             
              
</form>
</div>	      
);
      }
